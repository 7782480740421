/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    const angular = require('angular');

    // controllers
    const DailyWorkflowCtrl = require("./controllers/daily-workflow/DailyWorkflowCtrl");
    const WorkflowContainerCtrl = require("./controllers/workflow-container/WorkflowContainerCtrl");
    const ServiceOrderWorkflowCtrl = require("./controllers/service-order/ServiceOrderWorkflowCtrl");

    // services
    const WorkFlowService = require("./services/WorkFlowService");
    const ServiceOrderService = require("./services/ServiceOrderService");
    const mnWorkflowCustomFilter = require("./components/workflow-filter/workflow-filter");

    angular
        .module("medinet")

        .controller('DailyWorkflowCtrl', DailyWorkflowCtrl)
        .controller('WorkflowContainerCtrl', WorkflowContainerCtrl)
        .controller('ServiceOrderWorkflowCtrl', ServiceOrderWorkflowCtrl)
        .component('mnWorkflowCustomFilter', mnWorkflowCustomFilter)

        .service("ServiceOrderService", ServiceOrderService)
        .service("WorkFlowService", WorkFlowService)

    ;

})();
