(function () {

    'use strict';

    class PatientMovementsStateCtrl {
        constructor($translate, articleService, system, $stateParams, $state) {
            this.articleService = articleService;
            this.system = system;
            this.$stateParams = $stateParams;
            this.$state = $state;
            this.$translate = $translate;
            this.patientCallback = this.patientCallback.bind(this);
            this.removePatient = this.removePatient.bind(this);
            this.dateFormat = system['date_format'].js;
        }

        static get $inject() {
            return ["$translate", "articleService", "system", "$stateParams", "$state"];
        }

        $onInit() {
            this.patient = null;
            this.stateQuery = {
                from_date: moment().startOf('year').format(this.dateFormat),
                to_date: moment().format(this.dateFormat)
            };
            this.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            this.options = [5, 10, 20, 100];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            }
            this.maxToDate = new Date(moment());
            this.maxFromDate = new Date(moment(this.stateQuery.to_date, this.dateFormat));
            this.historic_lines = [];
        }

        loadSuccess(data) {
            this.historic_lines = data;
        }

        handleChange(ev) {
            this.promise = this.articleService.getPatientMovementState({
                patient: _.get(this.patient, 'id'),
                from_date: _.get(this.stateQuery, 'from_date'),
                to_date: _.get(this.stateQuery, 'to_date')
            }).then(this.loadSuccess.bind(this));
        }

        patientCallback(patient) {
            this.patient = patient;
            this.handleChange();
        }

        removePatient() {
            this.patient = null;
            this.handleChange();

        }

        formatItems(items) {
            return items.join(" - ");
        }

    }

    module.exports = PatientMovementsStateCtrl;
})();
