(function () {

    'use strict';

    class ServiceOrderWorkflowCtrl {
        constructor(
            $mdPanel, $scope, $compile, moment, ServiceOrderService, $state, mnWebSocket, system, $q, configService, $mdDialog, $element, $translate, frontDeskService, $mdToast) {
            this.$q = $q;
            this.state = $state;
            this.moment = moment;
            this.ws = mnWebSocket;
            this.dialog = $mdDialog;
            this.element = $element;
            this.translate = $translate;
            this.service = ServiceOrderService;
            this.configService = configService;
            this.scope = $scope;
            this.frontDeskService = frontDeskService;
            this.toast = $mdToast;

            this.list = [];
            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;


            this.filters = {};
            this.dateTimeFormat = system['date_format'].naive;

            this.views = [];
            this.selectedView = {};
            this.columns = this.service.getColumnConfiguration();
            this.subColumns = this.service.getSubColumnConfiguration();
            this.getData = this.getData.bind(this);
            this.onPaginate = this.onPaginate.bind(this);

        }

        static get $inject() {
            return [
                '$mdPanel', '$scope', '$compile', 'moment', 'ServiceOrderService', '$state', 'mnWebSocket', 'system', '$q', 'configService', '$mdDialog', "$element", "$translate", "frontDeskService", "$mdToast"];
        }

        $onInit() {
            this.ticket_status = ["MISSED", "REJECTED", "ACCEPTED", "COMPLETED", "IN_PROGRESS", "ON_HOLD", "CANCELLED", "SCHEDULED", "RESCHEDULED"];
            this.options = [5, 15, 20, 50, 100];
            this.toggle = false;
            this.handleGlobalSearch = _.mnDelay(this.getData, 400);
            this.query = {
                page: 1,
                search_all: "",
                limit: 20,
                columns: this.columns
            };

            this.refreshDataCall();
            this.ws.sub("extranet.HealthCareServiceOrder.notify", 'service-order-workflow', (data) => {
                this.refreshDataCall();
            });
            this.scope.$on("$destroy", () => this.onDestroy())
        }

        onDestroy() {
            this.ws.unsub("extranet.HealthCareServiceOrder.notify", 'service-order-workflow');
        }


        refreshDataCall() {
            this.promise = this.service.getHealthCareServiceOrders(this.query)
                .then(data => {
                    this.list = data['list'];
                    this.total = data['length'];
                    if (this.total < ((this.query.page - 1) * this.query.limit)) {
                        this.query.page = 1;
                    }
                });
        }

        countColumns() {
            return this.service.subColumnConfiguration.length + 1;
        }

        editCarePlan(item, ev) {
            let opts = null;
            if (item) opts = {
                'id': item.id,
                'type': 'plan',
            }
            else opts = {
                'id': null,
                'type': 'plan',
            }

            this.service.getOrderMedicalCarePlan(item.id).then(data => {
                this.state.go("app.visit.medical-care.form", {
                    visitId: item.visit,
                    pId: item.patient.id,
                    'id': data,
                    'type': 'plan',
                    'serviceOrder': item.id
                }).then(data => _.noop);
            })


        }


        entryResume(item, ev) {
            this.frontDeskService.entryResume({
                onlyResume: true,
                entry: null,
                'patient-id': item.patient.id
            }, ev);
        }

        patientFile(item) {
            return this.state.go("app.patient-form", {'patient_id': item.patient.id});
        }


        getData() {
            this.refreshDataCall();
        }

        onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            this.getData();
        }

        handleToggle(event) {
            this.toggle = !this.toggle;
        }

        addTicket(item, ev) {
            let dialog = _.assign({}, require('specifics/dialogs/service-order-scheduling-dialog/service-order-scheduling-dialog'), {
                multiple: true,
                targetEvent: null,
                locals: {
                    event: {
                        execution_date: this.moment().format(this.dateFormat),
                        start_time: "09:00",
                        end_time: `09:30`,
                        reason: null,
                        patient: item.patient,
                        service_order: item
                    }
                }
            });

            this.dialog.show(dialog).then(_.noop);
        }

        editTicket(order, ticket) {
            this.service.getTicket(ticket.id).then(item => {
                let dialog = _.assign({}, require('specifics/dialogs/service-order-scheduling-dialog/service-order-scheduling-dialog'), {
                    multiple: true,
                    targetEvent: null,
                    locals: {
                        event: _.assignIn(item, {patient: order.patient})
                    }
                });
                this.dialog.show(dialog).then(_.noop);
            });
        }

        cancelTicket(ticket) {
            this.service.cancelTicket(ticket.id).then(data => {

            }, (err) => {
                let toast = this.toast.simple()
                    .textContent(this.translate.instant('service_order_workflow.cancel_delete_warning'))
                    .position("top right")
                    .hideDelay(3000);
                this.toast.show(toast);
            });
        }
    }

    module.exports = ServiceOrderWorkflowCtrl;

})();
