/**
 * Created by Hp on 19/06/2017.
 */
(function () {

    'use strict';

    let mnWarehouse = {
        controller: MnWarehouseController,
        controllerAs: "vm",
        bindings: {
            stockDocument: "=parentModel",
            model: "=ngModel",
            disabled: "=mnDisabled",
            change: "<?mnChange",
            cls:"=cls",
            disableDefault:"=disableDefault",
            mnDefaultFilter:"=mnDefaultFilter"
        },

        template: tpl,
    };
    MnWarehouseController.$inject = ["$scope", "warehouseService"];

    function MnWarehouseController($scope, warehouseService) {
        let vm = this;
        vm.$onInit = init;
        vm.changeDocWarehouse = changeDocWarehouse;
        vm.handleFilter = handleFilter;

        function init() {
            let firstCall = true;
            let killWatch = $scope.$watch('vm.stockDocument', function (newVal) {
                if (firstCall && _.isUndefined(newVal)) {
                    firstCall = false;
                    return null;
                }
                else {
                    if (!_.has(newVal, 'warehouse')) {
                        vm.promise = warehouseService.getDefault().then(success);
                        _.isFunction(killWatch) ? killWatch() : _.noop();
                    }
                }
            });

            function success(data) {
                if (!_.has(vm.stockDocument, 'warehouse') && !vm.disableDefault) vm.model = data;
            }
        }

        function changeDocWarehouse() {
            if (_.isFunction(vm.change)) vm.change(vm.model);
            else {
                if (_.has(vm.stockDocument, 'lines')) {
                    _.forEach(vm.stockDocument.lines, function (line) {
                        line.warehouse = vm.model;
                    })
                }
            }
        }
        
        function handleFilter(w) {
            return !_.chain(w).get('prohibitions', []).find((e) => {
                return e == vm.cls;
            }).value();
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        const required = $elem.is("[required]") ? " required " : "";
        const label = attrs['mnLabel'] ? attrs['mnLabel'] : "warehouse";

        return `
            <mn-select url="/api/warehouse/" value="name" label="${label}" ng-model="vm.model" ${required} 
                ng-disabled="vm.disabled" ng-change="vm.changeDocWarehouse()" filter="vm.handleFilter">
            </mn-select>
        `;
    }

    module.exports = mnWarehouse;

})();