/**
 * Created by Hp on 09/05/2017.
 */


(function () {
    const angular = require('angular');

    const StockCtrl = require('./controllers/StockCtrl');
    const ArticleCtrl = require('./controllers/ArticleCtrl');
    const ArticleFormCtrl = require('./controllers/ArticleFormCtrl');
    const ProviderCtrl = require('./controllers/ProviderCtrl');
    const FamilyCtrl = require('./controllers/FamilyCtrl');
    const StockDocListCtrl = require('./controllers/StockDocListCtrl');
    const MovementFormCtrl = require('./controllers/MovementFormCtrl');
    const OrderFormCtrl = require('./controllers/OrderFormCtrl');
    const ReceiptFormCtrl = require('./controllers/ReceiptFormCtrl');
    const DepositionFormCtrl = require('./controllers/DepositionFormCtrl');
    const ReturnFormCtrl = require('./controllers/ReturnFormCtrl');
    const DeliveryFormCtrl = require('./controllers/DeliveryFormCtrl');
    const InventoryFormCtrl = require('./controllers/InventoryFormCtrl');
    const PurchaseInvoiceFormCtrl = require('./controllers/PurchaseInvoiceFormCtrl');
    const warehouseCtrl = require('./controllers/warehouseCtrl');
    const PriceRequestFormCtrl = require('./controllers/PriceRequestFormCtrl');
    const TransformationFormCtrl = require('./controllers/TransformationFormCtrl');
    const WarehouseExchangeCtrl = require('./controllers/WarehouseExchangeCtrl');
    const CreditNoteCtrl = require('./controllers/CreditNoteCtrl');
    const OpeningBalanceCtrl = require('./controllers/OpeningBalanceCtrl');
    const MovementStateCtrl = require('./controllers/MovementStateCtrl');
    const ConsignmentCtrl = require('./controllers/ConsignmentCtrl');
    const ConsignmentReturnCtrl = require('./controllers/ConsignmentReturnCtrl');
    const TariffCtrl = require('./controllers/TariffCtrl');
    const PurchaseRequestCtrl = require('./controllers/PurchaseRequestCtrl');
    const SerialsLotsStateCtrl = require('./controllers/SerialsLotsStateCtrl');
    const RecalculateArticleStockCtrl = require('./controllers/RecalculateArticleStockCtrl');
    const BusinessDetailCtrl = require('./controllers/BusinessDetailCtrl');
    const PatientMovementsStateCtrl = require('./controllers/PatientMovementsStateCtrl');
    const WarehouseExchangeStateCtrl = require('./controllers/WarehouseExchangeStateCtrl');
    const MovementsByBusinessCtrl = require('./controllers/MovementsByBusinessCtrl');
    const ExpiredArticlesHistoricCtrl = require('./controllers/ExpiredArticlesHistoricCtrl');
    const ArticlesShortagesCtrl = require('./controllers/ArticlesShortagesCtrl');

    const mnFamily = require('./components/mn-family');
    const mnProvider = require('./components/mn-provider');
    const mnDocumentLines = require('./components/mn-document-lines');
    const linesHeader = require('./components/document-lines-header');
    const mnDocumentNumber = require('./components/document-number');
    const articleHistoric = require('./components/article-historic');
    const mnWarehouse = require('./components/mn-warehouse');
    const mnMedicine = require('./components/mn-medicine');
    const stockDocActions = require('./components/stock-doc-actions');
    const multiProvider = require('./components/multi-provider');
    const mnArticle = require('./components/mn-article');
    const mnDocumentComment= require('./components/mn-document-comment');
    const mnDocumentDetails= require('./components/mn-document-details');
    const mnPaymentCondition= require('./components/mn-payment-conditions');
    const mnTariffDetails= require('./components/mn-tariff-details');
    const mnSelectDocument= require('./components/mn-select-document');


    const familyService = require('./services/familyService');
    const providerService = require('./services/providerService');
    const articleService = require('./services/articleService');
    const stockService = require('./services/stockService');
    const serialService = require('./services/serialService');
    const inventoryService = require('./services/inventoryService');
    const purchaseService = require('./services/purchaseService');
    const saleService = require('./services/saleService');
    const warehouseService = require('./services/warehouseService');
    const lotService = require('./services/lotService');
    const tariffService = require('./services/tariffService');

    const blockMovement = require('./directives/block-movement');
    const arrowSelector = require('./directives/arrow-selector');
    const moveRightLeft = require('./directives/move-right-left');
    const focusMe = require('./directives/focus-me');
    const TableLegend = require('./directives/table-legend');
    const mnDuration= require('./directives/mn-duration');


    angular
        .module("medinet")

        .component('mnFamily', mnFamily)
        .component('mnProvider', mnProvider)
        .component('mnDocumentLines', mnDocumentLines)
        .component('linesHeader', linesHeader)
        .component('mnDocumentNumber', mnDocumentNumber)
        .component('articleHistoric', articleHistoric)
        .component('mnWarehouse', mnWarehouse)
        .component('mnMedicine', mnMedicine)
        .component('stockDocActions', stockDocActions)
        .component('multiProvider', multiProvider)
        .component('mnArticle', mnArticle)
        .component('mnDocumentComment', mnDocumentComment)
        .component('mnDocumentDetails', mnDocumentDetails)
        .component('mnPaymentCondition', mnPaymentCondition)
        .component('mnTariffDetails', mnTariffDetails)
        .component('mnSelectDocument', mnSelectDocument)

        .service('familyService', familyService)
        .service('providerService', providerService)
        .service('articleService', articleService)
        .service('stockService', stockService)
        .service('serialService', serialService)
        .service('inventoryService', inventoryService)
        .service('purchaseService', purchaseService)
        .service('saleService', saleService)
        .service('warehouseService', warehouseService)
        .service('lotService', lotService)
        .service('tariffService', tariffService)

        .directive('blockMovement', blockMovement)
        .directive('arrowSelector', arrowSelector)
        .directive('moveRightLeft', moveRightLeft)
        .directive('focusMe', focusMe)
        .directive('mnTableLegend', TableLegend)
        .directive('mnDuration', mnDuration)

        .controller('StockCtrl', StockCtrl)
        .controller('ArticleCtrl', ArticleCtrl)
        .controller('ArticleFormCtrl', ArticleFormCtrl)
        .controller('ProviderCtrl', ProviderCtrl)
        .controller('FamilyCtrl', FamilyCtrl)
        .controller('StockDocListCtrl', StockDocListCtrl)
        .controller('MovementFormCtrl', MovementFormCtrl)
        .controller('OrderFormCtrl', OrderFormCtrl)
        .controller('ReceiptFormCtrl', ReceiptFormCtrl)
        .controller('DepositionFormCtrl', DepositionFormCtrl)
        .controller('ReturnFormCtrl', ReturnFormCtrl)
        .controller('DeliveryFormCtrl', DeliveryFormCtrl)
        .controller('InventoryFormCtrl', InventoryFormCtrl)
        .controller('PurchaseInvoiceFormCtrl', PurchaseInvoiceFormCtrl)
        .controller('warehouseCtrl', warehouseCtrl)
        .controller('PriceRequestFormCtrl', PriceRequestFormCtrl)
        .controller('WarehouseExchangeCtrl', WarehouseExchangeCtrl)
        .controller('CreditNoteCtrl', CreditNoteCtrl)
        .controller('OpeningBalanceCtrl', OpeningBalanceCtrl)
        .controller('TransformationFormCtrl', TransformationFormCtrl)
        .controller('MovementStateCtrl', MovementStateCtrl)
        .controller('ConsignmentCtrl', ConsignmentCtrl)
        .controller('TariffCtrl', TariffCtrl)
        .controller('ConsignmentReturnCtrl', ConsignmentReturnCtrl)
        .controller('PurchaseRequestCtrl', PurchaseRequestCtrl)
        .controller('SerialsLotsStateCtrl', SerialsLotsStateCtrl)
        .controller('RecalculateArticleStockCtrl', RecalculateArticleStockCtrl)
        .controller('BusinessDetailCtrl', BusinessDetailCtrl)
        .controller('PatientMovementsStateCtrl', PatientMovementsStateCtrl)
        .controller('WarehouseExchangeStateCtrl', WarehouseExchangeStateCtrl)
        .controller('ExpiredArticlesHistoricCtrl', ExpiredArticlesHistoricCtrl)
        .controller('ArticlesShortagesCtrl', ArticlesShortagesCtrl)
        .controller('MovementsByBusinessCtrl', MovementsByBusinessCtrl);
})();