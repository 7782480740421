(function () {

    'use strict';

    class ExpiredArticlesHistoricCtrl {
        constructor($translate, stockService, system, $stateParams, $state, $q) {
            this.stockService = stockService;
            this.system = system;
            this.$stateParams = $stateParams;
            this.$state = $state;
            this.$translate = $translate;
            this.q = $q;
            this.dateFormat = system['date_format'].js;

            this.handleChange = this.handleChange.bind(this);
            this.printSearch = () => this.printSearchCall();

        }

        static get $inject() {
            return ["$translate", "stockService", "system", "$stateParams", "$state", "$q"];
        }

        $onInit() {
            this.promise = this.stockService.getExpiredArticlesState(this.formatQuery()).then(this.loadSuccess.bind(this));
            this.stateQuery = {
                from_date: null,
                to_date: null
            };
            this.query = {
                limit: 50,
                page: 1,
                order: "-id"
            }
            this.options = [5, 10, 20, 100];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            }
            this.historic_lines = [];
        }

        loadSuccess(data) {
            this.historic_lines = data;
        }

        handleChange(ev) {
            this.promise = this.stockService.getExpiredArticlesState(this.formatQuery()).then(this.loadSuccess.bind(this));
        }

        formatQuery() {
            return {
                from_date: _.get(this.stateQuery, 'from_date'),
                to_date: _.get(this.stateQuery, 'to_date')
            }
        }

        exportXls() {
            let viewColumns = [
                {
                    label: 'code',
                    key: 'code',
                    type: 'text'
                },
                {
                    label: 'lot_number',
                    key: 'lot_code',
                    type: 'text'
                },
                {
                    label: 'expiration_date',
                    key: 'expiration_date',
                    type: 'text'
                },
                {
                    label: 'qte',
                    key: 'expired_qte',
                    type: 'text'
                }

            ]

            function reduceFunc(items, item) {
                return _.set(items, item.key, this.$translate.instant(item.label));
            }

            let translatedColumns;
            translatedColumns = _.reduce(viewColumns, reduceFunc.bind(this), {
                file_name:  this.$translate.instant('expired_articles_historic'),
                page_name: this.$translate.instant('export_excel_page_name')
            });
            let excelColumns;
            excelColumns = _.map(viewColumns, (e) => {
                return _.pick(e, 'key', 'type');
            });
            this.stockService.exportExpiredArticlesXls(this.formatQuery(), translatedColumns, excelColumns).then((data) => {
                data.download();
            });
        }


        getBalance() {
            return _.sumBy(this.historic_lines, 'expired_qte');
        }


        printSearchCall() {
            const deferred = this.q.defer();

            setTimeout(() => {
                deferred.resolve(this.formatQuery());
            }, 300);

            return deferred.promise;
        }
    }

    module.exports = ExpiredArticlesHistoricCtrl;
})();
