(function () {

    'use strict';

    class ArticlesShortagesCtrl {
        constructor($translate, stockService, system, $stateParams, $state, $q) {
            this.stockService = stockService;
            this.system = system;
            this.$stateParams = $stateParams;
            this.$state = $state;
            this.$translate = $translate;
            this.q = $q;
            this.dateFormat = system['date_format'].js;

            this.handleChange = this.handleChange.bind(this);
            this.printSearch = () => this.printSearchCall();

        }

        static get $inject() {
            return ["$translate", "stockService", "system", "$stateParams", "$state", "$q"];
        }

        $onInit() {
            this.promise = this.stockService.getArticlesShortagesState(this.formatQuery()).then(this.loadSuccess.bind(this));

            this.stateQuery = {
                from_date: null,
                to_date: null
            };
            this.query = {
                limit: 50,
                page: 1,
                order: "-id"
            }

            this.options = [5, 10, 20, 100];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            }
            this.maxToDate = new Date(moment());
            this.maxFromDate = new Date(moment(this.stateQuery.to_date, this.dateFormat));
            this.historic_lines = [];
        }

        loadSuccess(data) {
            this.historic_lines = data;
        }

        handleChange(ev) {
            this.promise = this.stockService.getExpiredArticlesState(this.formatQuery()).then(this.loadSuccess.bind(this));
        }

        formatQuery() {
            return {
                from_date: _.get(this.stateQuery, 'from_date'),
                to_date: _.get(this.stateQuery, 'to_date')
            }
        }

        exportXls() {
            let viewColumns = [
                {
                    label: 'code',
                    key: 'code',
                    type: 'text'
                },
                {
                    label: 'store_real_qte',
                    key: 'qte',
                    type: 'text'
                },
                {
                    label: 'store_min_qte',
                    key: 'min_qte',
                    type: 'text'
                }]

            function reduceFunc(items, item) {
                return _.set(items, item.key, this.$translate.instant(item.label));
            }

            let translatedColumns;
            translatedColumns = _.reduce(viewColumns, reduceFunc.bind(this), {
                file_name: this.$translate.instant('articles_shortages_historic'),
                page_name: this.$translate.instant('export_excel_page_name')
            });
            let excelColumns;
            excelColumns = _.map(viewColumns, (e) => {
                return _.pick(e, 'key', 'type');
            });
            this.stockService.exportArticlesShortagesXls(this.formatQuery(), translatedColumns, excelColumns).then((data) => {
                data.download();
            });
        }

        isSelected(item) {
            return _.chain(this.stateQuery).get('SelectedDocuments', []).find((e) => {
                return e === item;
            }).value();
        }


        toggleDocument(p) {
            let idx = this.stateQuery.SelectedDocuments.indexOf(p);
            if (idx > -1) {
                this.stateQuery.SelectedDocuments.splice(idx, 1);
            } else {
                this.stateQuery.SelectedDocuments.push(p);
            }
            this.handleChange(null);
        }

        getBalance() {
            return _.sumBy(this.historic_lines, 'expired_qte');
        }

        showDetails(line) {
            if (this.selectedItem == line) {
                this.selectedItem = -1;
            } else {
                this.selectedItem = line;
            }
        }

        onDetailsPaginate(page, limit) {
            this.itemsQuery = _.assign(this.itemsQuery, {page: page, limit: limit});
        }


        printSearchCall() {
            const deferred = this.q.defer();

            setTimeout(() => {
                deferred.resolve(this.formatQuery());
            }, 300);

            return deferred.promise;
        }
    }

    module.exports = ArticlesShortagesCtrl;
})();
