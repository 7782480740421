/**
 * Created by Hp on 24/03/2017.
 */
(function () {

    'use strict';

    let mnDocumentLines = {
        controller: MnDocumentLinesController,
        controllerAs: "vm",
        bindings: {
            stockDocument: "=ngModel",
            documentCls: "=documentCls",
            documentType: "=documentType",
            readOnly: "=readOnly",
            viewHiding: "=viewHiding",
            noPagination: "=noPagination",
            fileContext: "=fileContext"
        },
        template: tpl,
    };

    MnDocumentLinesController.$inject = ["$scope", "$mdDialog", "$translate", "listService", "$attrs", "$q", "articleService", "lotService", "dragulaService", "system"];

    function MnDocumentLinesController($scope, $mdDialog, $translate, listService, attrs, $q, articleService, lotService, dragulaService, system) {
        let vm = this;

        vm.$onInit = init;
        vm.onPaginate = onPaginate;
        vm.removeDetailLine = removeDetailLine;
        vm.lotButtonClick = lotButtonClick;
        vm.serialButtonClick = serialButtonClick;
        vm.sumLotsQte = sumLotsQte;
        vm.lineSubTotal = lineSubTotal;
        vm.linesVat = linesVat;
        vm.untaxedTotal = untaxedTotal;
        vm.vatTotal = vatTotal;
        vm.taxedTotal = taxedTotal;
        vm.getTheoQte = getTheoQte;
        vm.getTheoPmp = getTheoPmp;
        vm.qteGap = qteGap;
        vm.pmpGap = pmpGap;
        vm.sumQte = sumQte;
        vm.sumTheoQte = sumTheoQte;
        vm.sumPmp = sumPmp;
        vm.getLineTotal = getLineTotal;
        vm.getSummaryTotal = getSummaryTotal;
        vm.maxLineQte = maxLineQte;
        vm.isMaxError = isMaxError;
        vm.viewLineArticle = viewLineArticle;
        vm.checkNumber = checkNumber;
        let documentCls = !_.isUndefined(attrs['documentCls']) ? attrs['documentCls'] : null;
        vm.totalToText = totalToText;
        vm.updateWarehouse = updateWarehouse;
        vm.chargeTotal = chargeTotal;

        let bag = "line-bag";

        function init() {
            // vm.fileContext=_.zipObject([vm.documentCls],[_.get(vm.stockDocument,'id')]);

            vm.query = {
                limit: vm.noPagination ? 50 : 10,
                page: 1,
                order: "-id"
            };
            vm.options = [5, 10, 20, 30];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }

            let promises = [listService.list('Tva'), articleService.warehousesList()]
            vm.promise = $q.all(promises).then(success);

            initDrag();

            function success(data) {
                vm.vatList = data[0];
                vm.warehouses = _.filter(data[1], (w) => {
                    return !_.chain(w).get('prohibitions', []).find((e) => {
                        return e == vm.documentCls;
                    }).value()
                });

            }
        }

        function initDrag() {
            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            let drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", _.mnDelay(moveLine, 400));
        }

        function moveLine() {
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            vm.total = vm.stockDocument.lines.length;
            if (vm.total < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function removeDetailLine(idx) {
            vm.stockDocument.lines.splice((vm.query.page - 1) * vm.query.limit + idx, 1);
        }

        function lotButtonClick(line) {
            let lot_dialog = null;
            if (_.includes(['IN', 'DP', 'ASSEMBLY_SOURCE', 'DISASSEMBLY_LINES'], vm.documentType)) {
                lot_dialog = 'in-lots-dialog.js';
            } else if (vm.documentType == 'Inventory') {
                lot_dialog = 'inventory-lots-dialog.js'
            } else if (_.includes(['OUT', 'RETURN', 'DISASSEMBLY_SOURCE', 'ASSEMBLY_LINES', 'WarehouseExchange', 'Consignment', 'ConsignmentReturn'], vm.documentType)) {
                lot_dialog = 'out-lots-dialog.js';
            } else if (vm.documentType == 'BR') {
                if (_.get(line, 'src_doc_cls') == 'GoodDeposition') {
                    lot_dialog = 'out-lots-dialog.js';
                } else {
                    lot_dialog = 'in-lots-dialog.js';
                }
            }

            const dialog = _.assign({}, require(`stock/dialogs/${lot_dialog}`), {
                locals: {
                    lots: _.cloneDeep(!_.isNil(line.lot_numbers) ? line.lot_numbers : []),
                    limit: line.qte,
                    mvt_type: vm.documentType,
                    mvt_code: vm.stockDocument.number,
                    mvtDocument: vm.stockDocument,
                    documentCls: vm.documentCls,
                    line: vm.documentCls === 'Inventory' ? _.assignIn(line, {warehouse: vm.stockDocument.warehouse}) : line,
                    readOnly: vm.readOnly,
                    sourceDoc: {'id': line.src_doc_id, '_model': line.src_doc_cls}

                }
            });

            $mdDialog.show(dialog).then(done);

            function done(data) {
                line.lot_numbers = data;
            }
        }

        function serialButtonClick(line) {
            let serial_dialog = null;
            if (_.includes(['IN', 'BR', 'DP', 'ASSEMBLY_SOURCE', 'DISASSEMBLY_LINES'], vm.documentType)) {
                serial_dialog = 'in-serials-dialog.js';
            }
            if (_.includes(['OUT', 'RETURN', 'DISASSEMBLY_SOURCE', 'ASSEMBLY_LINES', 'WarehouseExchange', 'Consignment', 'ConsignmentReturn'], vm.documentType)) {
                serial_dialog = 'out-serials-dialog.js';
            }
            if (vm.documentType == 'Inventory') {
                serial_dialog = 'inventory-serials-dialog.js'
            }

            if (vm.documentType == 'BR') {
                if (_.get(line, 'src_doc_cls') == 'GoodDeposition') {
                    serial_dialog = 'out-serials-dialog.js';
                } else {
                    serial_dialog = 'in-serials-dialog.js';
                }
            }
            const dialog = _.assign({}, require(`stock/dialogs/${serial_dialog}`), {
                locals: {
                    serials: _.cloneDeep(!_.isNil(line.serial_numbers) ? line.serial_numbers : []),
                    limit: line.qte,
                    mvt_type: vm.documentType,
                    article: line.article,
                    lineWarehouse: vm.documentCls === 'Inventory' ? vm.stockDocument.warehouse : line.warehouse,
                    line: vm.documentCls === 'Inventory' ? _.assignIn(line, {warehouse: vm.stockDocument.warehouse}) : line,
                    readOnly: vm.readOnly,
                    sourceDoc: {'id': line.src_doc_id, '_model': line.src_doc_cls}
                }
            });
            $mdDialog.show(dialog).then(function done(data) {
                line.serial_numbers = data;
            });
        }

        function sumLotsQte(line) {
            return _.sumBy(line.lot_numbers, 'store_qte');
        }

        function getLinePrice(line) {
            let price = 0.0;
            if (!line.is_comment) {
                if (vm.stockDocument.price_mode == 'TTC') {
                    price = !_.isNil(line.tva) ? (line.unit_price * 100) / (100 + parseInt(line.tva.value)) : line.unit_price;
                } else price = line.unit_price;
            }
            return price;
        }

        function getLineVat(line, price) {
            return (!_.isNil(line.tva) && !line.is_comment) ? ((line.qte || 0.0) * price * parseInt(line.tva.value)) / 100 : 0.0;
        }

        function getLineDiscount(line, price) {
            return (!_.isNil(line.discount) && !line.is_comment) ? (((line.qte || 0.0) * price) * line.discount) / 100 : 0.0;
        }

        function lineSubTotal(line) {
            let price = getLinePrice(line);
            return line.sub_total = ((line.qte || 0.0) * price - getLineDiscount(line, price)) + getLineVat(line, price);
        }

        function lineUntaxedSubTotal(line) {
            return !line.is_comment ? ((line.qte || 0.0) * getLinePrice(line) - getLineDiscount(line, getLinePrice(line))) : 0.0;
        }

        function untaxedTotal() {
            let total = !_.isNil(vm.stockDocument) ? _.round(_.sumBy(vm.stockDocument.lines, lineUntaxedSubTotal), 2) : 0.0;
            !_.isNil(vm.stockDocument) ? vm.stockDocument.untaxed_total = total : _.noop;
            return total;
        }

        function vatTotal() {
            let total = !_.isNil(vm.stockDocument) ? _.sumBy(vm.stockDocument.lines, function (item) {
                return _.round(getLineVat(item, getLinePrice(item)), 2);
            }) : 0.0;
            !_.isNil(vm.stockDocument) ? vm.stockDocument.vat_total = total : _.noop;
            return total;
        }

        function taxedTotal() {
            let total = untaxedTotal() + vatTotal();
            !_.isNil(vm.stockDocument) ? vm.stockDocument.taxed_total = total : _.noop;
            return total;
        }

        function chargeTotal() {
            return _.sumBy(_.get(vm.stockDocument, 'charges', []), 'price');
        }

        function getTheoQte(line) {
            return vm.readOnly ? line.theo_qte : line.theo_qte = _.get(_.find(_.get(line, 'article.theo_stores'),
                (s) => {
                    return s.warehouse == _.get(vm.stockDocument, 'warehouse.id')
                }), 'store_qte', 0)
        }

        function getTheoPmp(line) {
            return !vm.readOnly ? line['theo_pmp_price'] = line.article['pmp'] : line['theo_pmp_price'];
        }

        function qteGap(line) {
            if (!vm.stockDocument.is_valid) line.qte_diff = line.qte - line['theo_qte'];
            return line.qte_diff;
        }

        function pmpGap(line) {
            if (!vm.stockDocument.is_valid) line.pmp_diff = line['pmp_price'] - line.article['pmp'];
            return line.pmp_diff;
        }

        function sumQte() {
            return !_.isNil(vm.stockDocument) ? _.chain(vm.stockDocument.lines).filter({is_comment: false}).sumBy('qte').value() : 0.0;
        }

        function sumTheoQte() {
            return !_.isNil(vm.stockDocument) ? _.chain(vm.stockDocument.lines).filter({is_comment: false}).sumBy('theo_qte').value() : 0.0;
        }

        function sumPmp() {
            return !_.isNil(vm.stockDocument) ? _.chain(vm.stockDocument.lines).filter({is_comment: false}).sumBy('pmp_price').value() : 0.0;
        }

        function getLineTotal(line) {
            return line.qte * line.pmp_price;
        }

        function getSummaryTotal() {
            let total = !_.isNil(vm.stockDocument) ? _.chain(vm.stockDocument.lines).filter({is_comment: false}).sumBy(function (item) {
                return getLineTotal(item)
            }).value() : 0.0;
            !_.isNil(vm.stockDocument) ? vm.stockDocument.total = total : _.noop;
            return total
        }

        function isMaxError(line) {
            return vm.stockDocument.is_valid ? false: !_.isNil(line.qte) && line.qte > maxLineQte(line);
        }

        function maxLineQte(line) {
            if(vm.stockDocument.is_valid) return Infinity;
            else{
                switch (vm.documentCls) {
                    case "StockMovement" :
                        if (vm.documentType == 'OUT') {
                            return handleMaxQte(line);
                        } else {
                            return Infinity;
                        }
                    case "Transformation" :
                        if (vm.documentType == 'ASSEMBLY_LINES') {
                            return handleMaxQte(line);
                        } else {
                            return Infinity;
                        }
                    case "WarehouseExchange" :
                    case "Consignment" :
                        return handleMaxQte(line);
                    case "PurchaseReceipt" :
                        return;
                    case "GoodDeposition" :
                        return;
                    case "GoodReturn" :
                        return;
                    case "DeliveryForm" :
                        return handleMaxQte(line);
                    case "ConsignmentReturn" :
                        return;
                }
            }
        }

        function handleMaxQte(line) {
            if (_.get(line, 'article.accept_negative_stock')) {
                return Infinity;
            } else {
                let store = _.find(_.get(line, 'article.theo_stores'), {warehouse: _.get(line, 'warehouse.id')});
                if (store) {
                    let available_qte = store.store_qte - store.consigned_qte;
                    if (available_qte > _.get(line, 'article.store_min_qte') && _.get(line, 'article.store_min_qte') != 0) {
                        return _.get(line, 'article.store_min_qte')
                    } else {
                        return available_qte;
                    }
                } else {
                    return 0;
                }
            }
        }

        function viewLineArticle(ev, article) {
            $mdDialog.show(_.assign(require('stock/dialogs/add-article-dialog'), {
                targetEvent: ev,
                locals: {
                    article: article,
                    is_medicine: article.is_medicine,
                    readOnly: true
                }
            }))
        }

        function linesVat() {
            if (!_.isNil(vm.stockDocument)) {
                vm.vats = _.chain(vm.stockDocument.lines).filter(function (line) {
                    return !_.isNil(line.tva);
                }).groupBy(function (o) {
                    return o.tva.value
                }).transform(function (result, value, key) {
                    let vatSum = _.round(_.chain(value).sumBy(function (line) {
                        return getLineVat(line, getLinePrice(line));
                    }).value(), 2);
                    result[key] = vatSum
                }).value();

                let lis_vats = _.chain(vm.stockDocument.lines).filter(function (line) {
                    return !_.isNil(line.tva);
                }).map(function (o) {
                    return o.tva.value;
                }).uniq().value();


                return lis_vats;
            } else return [];

        }

        function totalToText() {
            return !_.isNil(vm.stockDocument) ? "Arrêté le présent document à la somme: " + vm.stockDocument['amount_text'] : '';
        }

        function checkNumber(ev, line) {
            if (line.qte > maxLineQte(line)) {
                line.qte = maxLineQte(line);
                ev.preventDefault();
            }
        }

        function updateWarehouse(line) {
            if (_.includes(['IN', 'DP', 'BR'], vm.documentType)) {
                _.forEach(line.serial_numbers, function (serial) {
                    serial.warehouse = line.warehouse;
                });
                _.forEach(line.lot_numbers, function (lot) {
                    lot.warehouse = line.warehouse;
                });
            }
        }
    }

    tpl.$inject = ["$attrs"];

    function tpl(attrs) {
        let label = attrs['mnLabel'];
        return label == 'inventory' ? require('stock/views/inventory-lines.tpl.html') : require('stock/views/stock-document-lines.tpl.html');
    }

    module.exports = mnDocumentLines;
})();