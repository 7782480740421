(function () {

    'use strict';

    class WarehouseExchangeStateCtrl {
        constructor($translate, stockService, system, $stateParams, $state) {
            this.stockService = stockService;
            this.system = system;
            this.$stateParams = $stateParams;
            this.$state = $state;
            this.$translate = $translate;
            this.handleChange = this.handleChange.bind(this);
            this.dateFormat = system['date_format'].js;
        }

        static get $inject() {
            return ["$translate", "stockService", "system", "$stateParams", "$state"];
        }

        $onInit() {
            this.stateQuery = {
                from_date: moment().startOf('year').format(this.dateFormat),
                to_date: moment().format(this.dateFormat)
            };
            this.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            this.options = [5, 10, 20, 100];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            }
            this.maxToDate = new Date(moment());
            this.maxFromDate = new Date(moment(this.stateQuery.to_date, this.dateFormat));
            this.historic_lines = [];
        }

        loadSuccess(data) {
            this.historic_lines = data;
        }

        handleChange(ev) {
            if (_.get(this.stateQuery, 'source.id') && _.get(this.stateQuery, 'destination.id')) {
                this.promise = this.stockService.getWarehouseExchangeState(this.formatQuery()).then(this.loadSuccess.bind(this));
            }
        }

        formatQuery() {
            return {
                source: _.get(this.stateQuery, 'source.id'),
                destination: _.get(this.stateQuery, 'destination.id'),
                from_date: _.get(this.stateQuery, 'from_date'),
                to_date: _.get(this.stateQuery, 'to_date')
            }
        }

        exportXls() {
            let viewColumns = [
                {
                    label: 'date',
                    key: 'doc_date',
                    type: 'text'
                },
                {
                    label: 'doc_number',
                    key: 'doc_number',
                    type: 'text'
                },
                {
                    label: 'code',
                    key: 'code',
                    type: 'text'
                },
                {
                    label: 'description',
                    key: 'short_desc',
                    type: 'text'
                },
                {
                    label: 'qte',
                    key: 'qte',
                    type: 'number'
                }

            ]

            function reduceFunc(items, item) {
                return _.set(items, item.key, this.$translate.instant(item.label));
            }

            let translatedColumns;
            translatedColumns = _.reduce(viewColumns, reduceFunc.bind(this), {
                file_name: _.join( [ this.$translate.instant('warehouse_exchange_historic'),
               _.get(this.stateQuery, 'source.name'), this.$translate.instant('to'),
                 _.get(this.stateQuery, 'destination.name') ],' '),
                page_name: this.$translate.instant('export_excel_page_name')
            });
            let excelColumns;
            excelColumns = _.map(viewColumns, (e) => {
                return _.pick(e, 'key', 'type');
            });
            this.stockService.exportXlsWarehouseExchangeState(this.formatQuery(), translatedColumns, excelColumns).then((data) => {
                data.download();
            });
        }

    }

    module.exports = WarehouseExchangeStateCtrl;
})();
