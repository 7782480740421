/**
 * Created by Hp on 09/03/2017.
 */
(function () {

    'use strict';

    module.exports = articleService;

    articleService.$inject = ["Upload", "mnWebSocket", "$http", "$q", "providerService", "familyService"];

    function articleService(Upload, mnWebSocket, $http, $q, providerService, familyService) {

        var self = this;

        self.saveArticle = saveArticle;
        self.getArticle = getArticle;
        self.getArticles = getArticles;
        self.deleteArticle = deleteArticle;
        self.checkCodeExistence = checkCodeExistence;
        self.changeMedicine = changeMedicine;

        self.checkArticleDependencies = checkArticleDependencies;
        self.loadMedicines = loadMedicines;

        self.getHistoric = getHistoric;
        self.warehousesList = warehousesList;
        self.getMovementState = getMovementState;
        self.getSerialsLotsMovements = getSerialsLotsMovements;
        self.recalculateArticleStore = recalculateArticleStore;
        self.updateArticleStore = updateArticleStore;
        self.printBarCodes = printBarCodes;
        self.getPatientMovementState = getPatientMovementState;

        function getArticle(id) {
            var deferred = $q.defer();
            var url = "/api/article/" + id + "/";

            $http.get(url)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteArticle(article_id) {
            var deferred = $q.defer();
            var url = "/api/article/" + article_id + "/";

            $http.delete(url)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function saveArticle(article, file) {

            var deferred = $q.defer();

            var url = "/api/article/";

            if (!_.isNil(article.id)) url += article.id + "/";

            Upload.upload({url: url, data: {data: Upload.json(article), file: file, file_name: "picture"}})
                .then(successCallback, errorCallback);

            function successCallback(response) {

                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function checkCodeExistence(code, article_id) {
            return mnWebSocket.call('stock.Article.check_code_existence', {
                code: code,
                article_id: article_id || null
            });
        }

        function getOrCreateMedicineInfo(query) {
            return mnWebSocket.call('stock.Article.get_medicine_info', query);
        }

        function changeMedicine(item, article, families) {
            if (!_.isNil(item)) {
                var family = _.chain(families).find({name: 'Médicaments'}).value();
                var sub_family = !_.isNil(item['therapeutic_class']) ? _.chain(families).find({name: item['therapeutic_class'].value}).value() : null;
                if (!_.isNil(item['laboratory'])) providerService.getProviderObject(item['laboratory'].value).then(success);

                if (_.isNil(family)) {
                    family = {name: 'Médicaments', parent_family: null};
                    families.push(family);
                }
                if (_.isNil(sub_family) && !_.isNil(item['therapeutic_class'])) {
                    sub_family = {name: item['therapeutic_class'].value, parent_family: family};
                    families.push(sub_family);
                }
                article.family = family;
                article.sub_family = sub_family;
                article.code = article.code || item.ean_13;
                article.short_desc = article.short_desc || item.commercial_name;
                article.sale_price = item.sell_public_price || 0.0;
            }

            function success(data) {
                if (_.isNil(data)) article.provider = {
                    social_reason: item['laboratory'].value.toUpperCase(),
                    tel: '0000000000'
                };
                else article.provider = data;
            }

        }

        function checkArticleDependencies(article_id) {
            return mnWebSocket.call('stock.Article.check_article_dependencies', {article_id: article_id});
        }

        function loadMedicines() {
            return mnWebSocket.call('stock.Article.create_medicine_articles');
        }

        function getHistoric(article_id, historic_type, filter) {
            return mnWebSocket.call('stock.Article.get_purchase_historic', {
                historic_type: historic_type,
                _pk: article_id,
                filter: filter
            });
        }

        function getMovementState(query) {
            return mnWebSocket.call('stock.Article.get_movement_state', query);
        }

        function getPatientMovementState(query) {
            return mnWebSocket.call('stock.Article.get_patient_movements',query);
        }

        function warehousesList() {
            return mnWebSocket.call('stock.Warehouse.get_warehouses');
        }

        function getSerialsLotsMovements(query) {
            return mnWebSocket.call('stock.Article.get_serials_lots_movements', query);
        }

        function recalculateArticleStore(article) {
            return mnWebSocket.call('stock.Article.handle_store_recalculate', {article});
        }

        function updateArticleStore(article, totals) {
            return mnWebSocket.call('stock.Article.update_article_store', {article, totals});
        }

        function getArticles(codes) {
            return mnWebSocket.call('stock.Article.get_articles_by_code', {codes});
        }

        function printBarCodes(pks) {
            return mnWebSocket.call('stock.Article.print_codes', {pks});
        }

    }
})();