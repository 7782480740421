(function () {

    'use strict';

    class RecalculateArticleStockCtrl {
        constructor($translate, articleService, system, $stateParams, $state, $mdToast, authService) {
            this.$translate = $translate;
            this.articleService = articleService;
            this.system = system;
            this.$stateParams = $stateParams;
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.handleArticleChange = this.handleArticleChange.bind(this);
            this.isRoot = authService.isRootProfile();
        }

        static get $inject() {
            return ["$translate", "articleService", "system", "$stateParams", "$state", "$mdToast", "authService"];
        }

        $onInit() {
            this.stateQuery = {
                article: null,
                warehouse: null,
                code: null,
                type: 'SERIAL'
            };
            this.query = {
                limit: 10,
                page: 1,
                order: "-id"
            };
            this.options = [5, 10, 20, 100];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
            this.promise = this.articleService.warehousesList().then(data => {
                this.warehouses = data;
            })
        }

        handleArticleChange(article) {
            this.stateQuery.article = article;
            this.articleService.recalculateArticleStore(_.get(article, 'id')).then(data => {
                this.historic_lines = _.get(data, 'lines');
                this.total_lines = _.get(data, 'totals');
            });
        }

        performUpdate() {
            this.articleService.updateArticleStore(_.get(this.stateQuery, 'article.id'), this.total_lines).then(data => {
                if (data) {
                    let simpleToast = this.$mdToast.simple()
                        .textContent(this.$translate.instant('update_done'))
                        .position("bottom left")
                        .hideDelay(3000);
                    this.$mdToast.show(simpleToast);
                }

            });
        }

        getWarehouseName(pk) {
            return _.chain(this.warehouses).find({id: pk}).get('name').value();
        }


        getSourceStoreById(line) {
            return _.get(_.find(line.store,{'_id':_.get(line, 'warehouse')}), 'name', '');
        }

        getDestinationStoreById(line) {
            return _.get(_.find(line.store,{'_id':_.get(line, 'destination')}), 'name', '');
        }
    }

    module.exports = RecalculateArticleStockCtrl;
})();
