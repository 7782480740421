(function () {

    'use strict';
    const FILTER_SETTINGS = require('shared/json/filter-operators.json');

    class workflowCustomFilterCtrl {
        constructor($scope, tableService, moment, system, $mdDialog) {
            this.tableService = tableService;
            this.moment = moment;
            this.dialog = $mdDialog;

            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;
            this.filterColumns = [];
            this.subFilterColumns = [];
            this.filterApplied = false;

            let killWatch = $scope.$watch('vm.columns', function (newVal) {
                if (_.isUndefined(newVal) || _.isEmpty(newVal)) return;
                else {
                    this.filterColumns = _.chain(newVal).reject((c) => {
                        return c['is_property']
                    }).map((e) => {
                        return this.formatColumnObject(e);

                    }).value();
                    this.mnFilters = this.mnFilters || [];

                    killWatch();
                }
            }.bind(this));

            let killWatch2 = $scope.$watch('vm.subColumns', function (newVal) {
                if (_.isUndefined(newVal) || _.isEmpty(newVal)) return;
                else {
                    this.subFilterColumns = _.chain(newVal).reject((c) => {
                        return c['is_property']
                    }).map((e) => {

                        return this.formatColumnObject(e);
                    }).value();
                    this.mnFilters = this.mnFilters || [];

                    killWatch();
                }
            }.bind(this));
        }


        static get $inject() {
            return ["$scope", "tableService", "moment", "system", "$mdDialog"];
        }

        $onInit() {
            this.promise = null;

            this.mnModel = this.mnModel ? this.mnModel : null;
            this.clear = () => {
                return this.clearFilter()
            };
        }

        formatColumnObject(c) {
            if (_.get(c, 'filter_fields')) {
                c['filter_fields'] = _.map(_.get(c, 'filter_fields', []), (f) => {
                    return this.formatColumnObject(f)
                })
            }
            return _.chain(c).pick(['label', 'type', 'order_by', 'filter_fields', 'choices']).assign({
                logical_operator: "and",
                operator: this.getDefaultOperator(c['type']),
                value: this.getDefaultValue(c['type']),
                value_1: this.getDefaultValue(c['type']),
            }).value();
        }

        clearFilter() {
            this.query = _.assign(this.query, {filter: null});
            this.reloadHandler();
            this.filterApplied = false;
            _.forEach(this.filterColumns, function (e) {
                e['isFilter'] = false
                e = _.assign(e, {
                    logical_operator: "and",
                    operator: this.getDefaultOperator(e['type']),
                    value: this.getDefaultValue(e['type']),
                    value_1: this.getDefaultValue(e['type']),
                    isFilter: false
                });
            }.bind(this));
            _.forEach(this.subFilterColumns, function (e) {
                e['isFilter'] = false
                e = _.assign(e, {
                    logical_operator: "and",
                    operator: this.getDefaultOperator(e['type']),
                    value: this.getDefaultValue(e['type']),
                    value_1: this.getDefaultValue(e['type']),
                    isFilter: false
                });
            }.bind(this));
        }

        applyFilter() {
            this.query = _.assign(this.query, {
                filter: _.filter(this.filterColumns, ['isFilter', true]),
                subFilter: _.filter(this.subFilterColumns, ['isFilter', true]),
                columns: this.columns,
                subColumns: this.subColumns
            })
            this.reloadHandler();
            this.filterApplied = true;
        }

        handleLogicalOperator(column) {
            _.get(column, 'logical_operator') == 'and' ? column['logical_operator'] = 'or' : column['logical_operator'] = 'and';
        }

        hasFilter() {
            return _.find(this.filterColumns, ['isFilter', true]) ? true : false;
        }

        getDefaultOperator(type) {
            return _.chain(FILTER_SETTINGS.operators)
                .find({type: type})
                .get('operators', [])
                .first()
                .value();
        }

        getDefaultValue(type, value = null) {
            switch (type) {
                case 'text':
                    return '';
                    break;
                case  'number':
                    return 0;
                    break;
                case 'date':
                    return this.moment().format(this.dateFormat);
                    break;
                case 'time':
                    return this.moment().format(this.timeFormat);
                    break;
            }
        }

        handleDateValue(column) {
            if (column.operator == '$between') {
                column.value = `${column.value_1}_${column.value_2}`;
            } else {
                column.value = column.value_1;
            }
        }

        handleValue(event, column) {
            if (column.operator == "$exists") column.value = true;
            if (column.operator == "$nexists") column.value = false;
        }

        checkFiltersExist() {
            return !_.isNil(_.find(this.filterColumns, ['isFilter', true]));
        }

        submit(handle_new = true) {
            if (handle_new) {
                this.dialog.show(_.assign(require('shared/dialogs/filter-dialog'), {
                    locals: {
                        filter: {query: _.filter(this.filterColumns, ['isFilter', true])}
                    },
                    focusOnOpen: false
                })).then(data => {
                    this.mnFilters.push(data);
                    this.currentFilter = data;
                    this.handleConfigFilters(this.currentFilter);
                }, _.noop);
            } else {
                this.tableService.handleFilter(_.assign(this.currentFilter, {query: _.filter(this.filterColumns, ['isFilter', true])})).then(function (data) {
                    if (data) {
                        // this.currentFilter = _.assign(this.currentFilter, data);

                        this.tableService.getColumns(this.mnModel, true).then(_.noop());
                    }
                }.bind(this));
            }
        }

        handleConfigFilters(filter, handle_new = true) {
            if (handle_new) {
                this.tableService.updateFilters(this.mnModel, _.map(_.cloneDeep(this.mnFilters), function (e) {
                    return _.omit(e, ['$$mdSelectId'])
                })).then(function (data) {
                    if (data) this.mnFilters = _.get(data, 'filters');

                }.bind(this));
            }

        }

        handleFilterChange() {

            if (this.currentFilter) {
                this.filterColumns = _.map(this.filterColumns, (e) => {
                    let f = _.find(_.cloneDeep(_.get(this.currentFilter, 'query')), (q) => {
                        return q['order_by'] == e['order_by'] ? q['isFilter'] = true : _.noop();
                    });

                    if (f && e.type == 'date') {
                        if (f.operator == '$between') {
                            let d = f.value.split('_');
                            f.value_1 = d[0]
                            f.value_2 = d[1];
                        } else f.value_1 = f.value;
                    }
                    return _.assign(e, f);
                });
            }
        }

        formatTab(cond) {

        }
    }

    module.exports = {
        bindings: {
            columns: '=?',
            subColumns: '=?',
            query: '=?',
            mnFilters: '=?',
            mnModel: '=?',
            reloadHandler: '=?'
        },
        template: require("./workflow-filter.html"), // or template
        controllerAs: "vm",
        controller: workflowCustomFilterCtrl,
    };

})();