(function () {

    'use strict';

    class MovementsByBusinessCtrl {
        constructor($translate, stockService, system, $stateParams, $state, $q) {
            this.stockService = stockService;
            this.system = system;
            this.$stateParams = $stateParams;
            this.$state = $state;
            this.$translate = $translate;
            this.q = $q;
            this.dateFormat = system['date_format'].js;

            this.handleChange = this.handleChange.bind(this);
            this.printSearch = () => this.printSearchCall();

        }

        static get $inject() {
            return ["$translate", "stockService", "system", "$stateParams", "$state", "$q"];
        }

        $onInit() {
            this.models = [
                'StockMovement',
                'GoodDeposition',
                'GoodReturn',
                'PurchaseReceipt',
                'Transformation',
                'WarehouseExchange',
                'ConsignmentReturn',
                'Consignment'];
            this.selectedItem = null;
            this.stateQuery = {
                from_date: moment().startOf('year').format(this.dateFormat),
                to_date: moment().format(this.dateFormat),
                SelectedDocuments: _.cloneDeep(this.models),
                business_detail: null
            };
            this.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            this.itemsQuery = {
                limit: 10,
                page: 1,
                order: "-_id"
            }
            this.options = [5, 10, 20, 100];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            }
            this.maxToDate = new Date(moment());
            this.maxFromDate = new Date(moment(this.stateQuery.to_date, this.dateFormat));
            this.historic_lines = [];
        }

        loadSuccess(data) {
            this.historic_lines = data;
        }

        handleChange(ev) {

            if (_.get(this.stateQuery, 'business_detail.id')) {
                this.promise = this.stockService.getMovementsByBusinessDetailState(this.formatQuery()).then(this.loadSuccess.bind(this));
            }else{
                this.historic_lines=[];
            }
        }

        formatQuery() {
            return {
                business_detail: _.get(this.stateQuery, 'business_detail.id'),
                business_detail_name: _.get(this.stateQuery, 'business_detail.name'),
                from_date: _.get(this.stateQuery, 'from_date'),
                to_date: _.get(this.stateQuery, 'to_date'),
                SelectedDocuments: _.get(this.stateQuery, 'SelectedDocuments'),
            }
        }

        exportXls() {
            let viewColumns = [
                {
                    label: 'doc_number',
                    key: 'doc_number',
                    type: 'text'
                },
                {
                    label: 'doc_date',
                    key: 'doc_date',
                    type: 'text'
                },
                {
                    label: 'code',
                    key: 'code',
                    type: 'text'
                },
                {
                    label: 'short_desc',
                    key: 'short_desc',
                    type: 'text'
                },
                {
                    label: 'qte',
                    key: 'qte',
                    type: 'number'
                },
                {
                    label: 'price',
                    key: 'price',
                    type: 'number'
                },
                {
                    label: 'mvt_source',
                    key: 'mvt_source',
                    type: 'text'
                },

                {
                    label: 'type',
                    key: 'type',
                    type: 'text'
                }

            ]

            function reduceFunc(items, item) {
                return _.set(items, item.key, this.$translate.instant(item.label));
            }

            let translatedColumns;
            translatedColumns = _.reduce(viewColumns, reduceFunc.bind(this), {
                file_name: _.join([this.$translate.instant('business_movements_historic'),
                    _.get(this.stateQuery, 'business_detail.name')], ' '),
                page_name: this.$translate.instant('export_excel_page_name')
            });
            let excelColumns;
            excelColumns = _.map(viewColumns, (e) => {
                return _.pick(e, 'key', 'type');
            });
            this.stockService.exportXlsBusinessDetailStat(this.formatQuery(), translatedColumns, excelColumns).then((data) => {
                data.download();
            });
        }

        isSelected(item) {
            return _.chain(this.stateQuery).get('SelectedDocuments', []).find((e) => {
                return e === item;
            }).value();
        }


        toggleDocument(p) {
            let idx = this.stateQuery.SelectedDocuments.indexOf(p);
            if (idx > -1) {
                this.stateQuery.SelectedDocuments.splice(idx, 1);
            } else {
                this.stateQuery.SelectedDocuments.push(p);
            }
            this.handleChange(null);
        }

        getPriceBalance() {
            return _.subtract(_.sumBy(this.historic_lines, 'out_total_price'), _.sumBy(this.historic_lines, 'in_total_price'));
        }

        showDetails(line) {
            if (this.selectedItem == line) {
                this.selectedItem = -1;
            } else {
                this.selectedItem = line;
            }
        }

        onDetailsPaginate(page, limit) {
            this.itemsQuery = _.assign(this.itemsQuery, {page: page, limit: limit});
        }


        printSearchCall() {
            const deferred = this.q.defer();

            setTimeout(() => {
                deferred.resolve(this.formatQuery());
            }, 300);

            return deferred.promise;
        }
    }

    module.exports = MovementsByBusinessCtrl;
})();
