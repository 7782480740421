/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = WarehouseExchangeCtrl;

    WarehouseExchangeCtrl.$inject = ["patientService", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "$mdDialog"];

    function WarehouseExchangeCtrl(patientService, $transition$, $scope, stockService, system, $state, $mdToast, $translate, $mdDialog) {
        var vm = this;

        var currentExchange = $transition$.params('to')["exchangeId"];
        var dateFormat = system['date_format'].js;
        vm.$onInit = init;
        vm.submit = submit;
        vm.invalidateExchange = invalidateExchange;
        vm.validateExchange = validateExchange;
        vm.save = save;
        vm.refreshLines=refreshLines;
        vm.isAllValid=isAllValid;
        vm.handleDestinationChange=handleDestinationChange;

        function init() {
            vm.is_new = !_.isNil(currentExchange) ? false : true;
            $scope.$watch('vm.exchange.is_valid', readOnlyExchange);
            vm.docType = 'WarehouseExchange';
            vm.modelName = 'WarehouseExchange';
            if (!_.isNil(currentExchange)) vm.promise = stockService.getStockDocument(currentExchange, 'warehouse-exchange').then(success);
            else   vm.exchange = {doc_date: moment().format(dateFormat), lines: []};
            function success(data) {
                vm.exchange = data;
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }
            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }
            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.exchange = data;
            if (_.isNil(currentExchange)) $state.go('app.stock.warehouse-exchange-form', {exchangeId: data.id}, {location: 'replace'});
        }


        function invalidateExchange() {
            return stockService.invalidateMovement(_.get(vm.exchange, 'id'), 'WarehouseExchange', vm.docType);
        }

        function validateExchange(data) {
            return stockService.validateMovement(_.get(data, 'id'), 'WarehouseExchange', vm.docType);
        }

        function readOnlyExchange() {
            vm.readOnly = (vm.exchange && vm.exchange.is_valid) ? vm.exchange.is_valid : false;
        }

        function save() {
            return stockService.saveStockDocument(vm.exchange, "warehouse-exchange", 'WarehouseExchange');
        }
        // TODO change article_store into theo_stores verifiy how to
        function refreshLines() {
            _.remove(vm.exchange.lines, function (item) {
                return _.isEmpty(_.filter(item.article['article_store'], function (store) {
                    return store.warehouse.id == vm.exchange.warehouse.id && store['store_qte'] > 0  ;
                }))
            });
        }
        function isAllValid() {
            return vm.exchange ? stockService.isAllValid(vm.exchange,  vm.docType , vm.modelName) : true;
        }
        function handleDestinationChange(doc) {
            if (_.has(vm.exchange, 'lines') && !_.isEmpty(doc)) {
                _.forEach(vm.exchange.lines, function (line) {
                    line.destination = doc;
                })
            }
        }

    }
})
();
